import styled from 'styled-components';

export const DesktopSidebarNav = styled.nav<{ isClient: boolean }>`
  background-color: ${({ isClient }) =>
    isClient ? 'rgb(var(--color-primary))' : 'rgb(var(--color-neutral3))'};
  color: rgb(var(--color-white));
  font-weight: 600;
  width: 29.4rem;
  height: calc(100vh - 10.4rem);
  overflow-y: auto;
  position: fixed;
  top: 10.4rem;
  left: 0;
  padding: 7.7rem 3.3rem;
  flex-shrink: 0;

  @media only screen and (min-width: 87.5em) {
    position: sticky;
    top: 10.4rem;
    left: 0;
    height: calc(100vh - 9.6rem);
  }
`;

export const DesktopSidebarNavListItem = styled.li<{
  isActive: boolean;
  logout?: boolean;
}>`
  text-transform: capitalize;
  width: fit-content;
  border-left: 4px solid transparent;
  padding-left: 1.6rem;
  transition: border-left-color 0.2s ease-in-out;

  & > a {
    display: flex;
    align-items: center;

    & > svg {
      margin-right: 1.6rem;
    }
  }

  &:not(:last-child) {
    margin-bottom: 4.4rem;
  }

  ${({ isActive }) => isActive && 'border-color: rgb(var(--color-secondary));'}

  ${({ logout }) =>
    logout &&
    `display: flex;
     align-items: center; 
     cursor: pointer;
     
     & > svg { 
       margin-right: 1.6rem;
     }`}
`;
