import {
  ReactNode,
  useState,
  forwardRef,
  useImperativeHandle,
  ForwardedRef,
} from 'react';
import { Root, Portal, Title, Description } from '@radix-ui/react-dialog';

import { SvgIcon } from '@gate-academy/react';

import {
  ModalClose,
  ModalContent,
  ModalOverlay,
  ModalTrigger,
  VisuallyHidden,
} from './modal.styles';

interface IModalProps {
  trigger: ReactNode;
  children: ReactNode | ((close: () => void) => ReactNode);
  hideCloseButton?: boolean;
  onClose?: () => void;
  disableEscapeDown?: boolean;
  disableOutsideClick?: boolean;
  title: string;
  description: string;
}

export type ModalRefActions = {
  open: () => void;
  close: () => void;
};

const Modal = forwardRef<unknown, IModalProps>(
  (
    {
      trigger,
      children,
      hideCloseButton,
      onClose,
      disableEscapeDown,
      disableOutsideClick,
      title,
      description,
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);

    const closeModal = () => {
      onClose?.();

      setIsOpen(false);
    };

    useImperativeHandle(ref, () => ({
      open: () => {
        setIsOpen(true);
      },
      close: closeModal,
    }));

    return (
      <Root open={isOpen} onOpenChange={setIsOpen}>
        <ModalTrigger asChild>{trigger}</ModalTrigger>
        <Portal>
          <ModalOverlay>
            <ModalContent
              ref={ref as ForwardedRef<HTMLDivElement>}
              onPointerDownOutside={(e) =>
                disableOutsideClick && e.preventDefault()
              }
              onEscapeKeyDown={(e) => disableEscapeDown && e.preventDefault()}
            >
              {!hideCloseButton && (
                <ModalClose>
                  <SvgIcon
                    fillColor="neutral5"
                    iconName="close"
                    onClick={closeModal}
                  />
                </ModalClose>
              )}

              <VisuallyHidden>
                <Title>{title}</Title>

                <Description>{description}</Description>
              </VisuallyHidden>

              {typeof children === 'function' ? children(closeModal) : children}
            </ModalContent>
          </ModalOverlay>
        </Portal>
      </Root>
    );
  }
);

Modal.displayName = 'Modal';
export default Modal;
