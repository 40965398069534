import styled from 'styled-components';

export const WalletTopUpContainer = styled.div`
  padding: 2rem;
  background: rgb(var(--color-white));
  border-radius: 0.8rem;
  margin: 0 auto;
  width: 90%;

  & > p {
    font-weight: 700;
    margin: 3rem 0;
    font-size: 2rem;
    text-align: center;
  }

  & > ol {
    padding: 3rem 2rem;
    max-width: 40rem;

    & > li {
      list-style-type: number;
      margin-bottom: 1rem;
    }
  }

  @media screen and (min-width: 29.4375em) {
    width: 37rem;
  }

  @media screen and (min-width: 56.2em) {
    width: 46rem;
  }

  @media screen and (min-width: 56.25em) {
    padding: 2rem 3rem;
  }
`;

export const PaymentOptionsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
  gap: 2rem;
`;

export const PaymentButtonWrapper = styled.div`
  width: 100%;
  border-radius: 0.8rem;
  position: relative;
  font-weight: 700;

  & > button {
    border-color: rgb(var(--color-primary));

    & > div {
      position: relative;
      width: 12rem;
      height: 2rem;
      margin: 0 auto;
      color: rgb(var(--color-primary));
      text-align: center;

      & > img {
      }
    }

    & > svg {
      width: 2rem;
      height: 2rem;
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }
`;
