const home = () => '/';

const checklists = () => '/checklists';

const checklist = (id: string) => checklists() + `/${id}`;

const engagements = () => '/engagements';

const engagement = (id: string) => engagements() + `/${id}`;

const reports = () => '/reports';

const report = (id: string) => reports() + `/${id}`;

const resetPassword = () => '/reset-password';

const selectTutor = (id: string) => `/select-tutor/${id}`;

const tutors = () => '/tutors';

const forgotPassword = () => '/forgot-password';

const login = () => '/log-in';

const wards = () => '/wards';

const ward = (id: string) => wards() + `/${id}`;

const tutorProfile = () => '/tutor-profile';

const settings = () => '/settings';

const tutees = () => '/tutees';

const schedule = () => '/schedule';

const wallet = () => '/wallet';

const transactions = () => '/transactions';

const session = (engagementId: string, sessionId: string) =>
  `/engagements/${engagementId}/sessions/${sessionId}`;

const routes = {
  checklists,
  checklist,
  engagements,
  engagement,
  reports,
  report,
  resetPassword,
  selectTutor,
  tutors,
  forgotPassword,
  login,
  wards,
  ward,
  tutorProfile,
  settings,
  home,
  tutees,
  schedule,
  session,
  wallet,
  transactions,
};

export default routes;
