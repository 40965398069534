import { useState } from 'react';

import {
  DashboardPlainCard,
  PageHeading,
  PageSubheading,
  ShowView,
  SvgIcon,
} from '@gate-academy/react';

import WalletTopUpModal from '../wallet-topup-modal/wallet-topup-modal';

const WalletBalanceInfo = () => {
  const [showBalanceInfoCard, setShowBalanceInfoCard] = useState(true);

  return (
    <>
      <ShowView when={showBalanceInfoCard}>
        <DashboardPlainCard style={{ position: 'relative' }}>
          <PageHeading>Top up your wallet</PageHeading>
          <PageSubheading>
            You don’t seem to have enough money in your wallet for all the
            upcoming sessions in this engagement
          </PageSubheading>

          <WalletTopUpModal />

          <SvgIcon
            iconName="close"
            style={{
              position: 'absolute',
              right: '1.6rem',
              top: '1.6rem',
              fill: 'rgba(var(--color-neutral5), 0.5)',
              cursor: 'pointer',
            }}
            onClick={() => {
              setShowBalanceInfoCard(false);
            }}
          />
        </DashboardPlainCard>
      </ShowView>
    </>
  );
};

export default WalletBalanceInfo;
