import dayjs from '~/lib/dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import useStore from '~/store';

dayjs.extend(utc);
dayjs.extend(timezone);

const useTZ = () => {
  const currentUser = useStore((state) => state.currentUser);

  dayjs.tz.setDefault(currentUser?.timezone || 'Africa/Lagos');

  return (date?: string | Date | null) => dayjs(date).tz(currentUser?.timezone);
};

export default useTZ;
