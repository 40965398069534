import Image from 'next/image';
import { ReactElement, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import {
  Btn,
  Button,
  Form,
  FormInputContainer,
  InputFooterText,
  ShowView,
  SvgIcon,
} from '@gate-academy/react';

import {
  PaymentButtonWrapper,
  PaymentOptionsContainer,
  WalletTopUpContainer,
} from './wallet-topup-modal.styles';

import Modal from '../modal-component/modal-component';

import useStore from '~/store';

import { Currency } from '@gate-academy/shared/lib';

import useTopUpWallet from '~/react-query/mutations/useTopUpWallet';

interface ITopUpFormProps {
  trigger?: ReactElement;
}

interface ITopUpFormInputTypes {
  amount: number;
}

const usdPaymentOptionInfo = [
  {
    image: 'zelle',
  },
  {
    image: 'wire-transfer',
  },
  {
    image: 'stripe',
  },
];

const nairaPaymentOptionInfo = [
  {
    name: 'bank transfer',
  },
  {
    name: 'paystack',
  },
];

const currenciesWithHeaders = [Currency.USD, Currency.NGN];

const getMinTopUpAmount = (currency: string) => {
  return currency === 'NGN' ? 1000 : 10;
};

const PaymentForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ITopUpFormInputTypes>({
    defaultValues: { amount: undefined },
  });

  const user = useStore((state) => state.currentUser);

  const userId = user?.id as string;
  const clientCurrency = user?.deductibleCurrency || 'NGN';

  const { mutate: topUpWallet, isLoading } = useTopUpWallet();

  const onSubmit: SubmitHandler<ITopUpFormInputTypes> = (data) => {
    topUpWallet({ id: userId, amount: Number(data.amount) });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormInputContainer style={{ marginTop: '3rem' }}>
        <label htmlFor="amount">
          Amount<span>*</span>
        </label>

        <input
          type="number"
          id="amount"
          min={getMinTopUpAmount(clientCurrency)}
          {...register('amount', {
            required: 'Please enter the amount to be paid',
            min: {
              value: getMinTopUpAmount(clientCurrency),
              message: `Amount cannot be less than ${getMinTopUpAmount(
                clientCurrency
              )}`,
            },
          })}
        />

        {!errors?.amount && (
          <InputFooterText
            noError
            text={`Min: ${getMinTopUpAmount(clientCurrency)}`}
          />
        )}

        {errors?.amount && (
          <InputFooterText text={errors?.amount?.message as string} />
        )}
      </FormInputContainer>
      <Button
        blackBg
        block
        style={{ marginTop: '1.6rem' }}
        isLoading={isLoading}
        loadingText="Loading..."
      >
        Top up
      </Button>
    </Form>
  );
};
const WalletTopUpModal = ({
  trigger = (
    <Button blackBg style={{ marginTop: '1.6rem', marginLeft: '0' }}>
      Top up
    </Button>
  ),
}: ITopUpFormProps) => {
  const [showPaymentOption, setShowPaymentOption] = useState(false);
  const [selectedPaymentOptionIndex, setSelectedPaymentOptionIndex] =
    useState(0);

  const clientCurrency = useStore(
    (state) => state.currentUser?.deductibleCurrency as Currency
  );

  return (
    <Modal
      trigger={trigger}
      onClose={() => setShowPaymentOption(false)}
      disableOutsideClick
      disableEscapeDown
      title="top up wallet modal"
      description="top up your wallet"
    >
      <ShowView when={!showPaymentOption}>
        <WalletTopUpContainer>
          {clientCurrency && currenciesWithHeaders.includes(clientCurrency) && (
            <p>How would you like to pay?</p>
          )}

          <ShowView when={clientCurrency === 'USD'}>
            <PaymentOptionsContainer>
              {usdPaymentOptionInfo.map((option, index) => (
                <PaymentButtonWrapper key={option.image}>
                  <Btn
                    transparentBg
                    style={{
                      borderBlockColor: 'rgb(var(--color-primary))',
                    }}
                    onClick={() => {
                      setSelectedPaymentOptionIndex(index);
                    }}
                    block
                  >
                    <div>
                      <ShowView when={index !== 1}>
                        <Image
                          src={`/${option.image}.svg`}
                          alt={`${option.image} logo`}
                          fill
                        />
                      </ShowView>
                      <ShowView when={index === 1}>
                        <p>WIRE TRANSFER</p>
                      </ShowView>
                    </div>

                    <ShowView when={selectedPaymentOptionIndex === index}>
                      <SvgIcon
                        iconName="check-circle-outline"
                        fillColor="neutral1"
                      />
                    </ShowView>
                  </Btn>
                </PaymentButtonWrapper>
              ))}
            </PaymentOptionsContainer>

            <ShowView when={selectedPaymentOptionIndex !== 2}>
              <ol>
                <li>
                  Send amount to
                  <ShowView when={selectedPaymentOptionIndex === 1}>
                    <p>
                      Bank Name: <strong>Bank of America, N.A.</strong>
                    </p>
                    <p>
                      Account Name: <strong>GATE Academy Tutoring USA</strong>
                    </p>
                    <p>
                      Account Number <strong>325194787943</strong>
                    </p>
                    <p>
                      Wire Routing Number: <strong>026009593</strong>
                    </p>
                  </ShowView>
                  <ShowView when={selectedPaymentOptionIndex === 0}>
                    <strong> gateeduideateng@gmail.com</strong>
                  </ShowView>
                </li>
                <li>
                  Sit tight, we will reach out to you via email confirming your
                  payment.
                </li>
              </ol>
            </ShowView>

            <ShowView when={selectedPaymentOptionIndex === 2}>
              <PaymentForm />
            </ShowView>
          </ShowView>

          <ShowView when={clientCurrency === 'NGN'}>
            <PaymentOptionsContainer>
              {nairaPaymentOptionInfo.map((option, index) => (
                <PaymentButtonWrapper key={option.name}>
                  <Btn
                    transparentBg
                    style={{
                      borderBlockColor: 'rgb(var(--color-primary))',
                    }}
                    onClick={() => {
                      setSelectedPaymentOptionIndex(index);
                    }}
                    block
                  >
                    <div>
                      <p>{option.name}</p>
                    </div>

                    <ShowView when={selectedPaymentOptionIndex === index}>
                      <SvgIcon
                        iconName="check-circle-outline"
                        fillColor="neutral1"
                      />
                    </ShowView>
                  </Btn>
                </PaymentButtonWrapper>
              ))}
            </PaymentOptionsContainer>

            <ShowView when={selectedPaymentOptionIndex === 0}>
              <ol>
                <li>
                  Send amount to
                  <p>
                    Bank Name: <strong>Sterling Bank</strong>
                  </p>
                  <p>
                    Account Name:{' '}
                    <strong>GATE Eduideate Company Limited</strong>
                  </p>
                  <p>
                    Account Number <strong>0073988082</strong>
                  </p>
                  <strong>OR</strong>
                  <p>
                    Bank Name: <strong>Guaranty Trust Bank PLC</strong>
                  </p>
                  <p>
                    Account Name:{' '}
                    <strong>GATE Eduideate Company Limited</strong>
                  </p>
                  <p>
                    Account Number <strong>0852304049</strong>
                  </p>
                </li>
                <li>
                  Sit tight, we will reach out to you via email confirming your
                  payment.
                </li>
              </ol>
            </ShowView>

            <ShowView when={selectedPaymentOptionIndex === 1}>
              <PaymentForm />
            </ShowView>
          </ShowView>

          {clientCurrency &&
            !currenciesWithHeaders.includes(clientCurrency) && <PaymentForm />}
        </WalletTopUpContainer>
      </ShowView>
    </Modal>
  );
};

export default WalletTopUpModal;
