import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import advancedDateFormats from 'dayjs/plugin/advancedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import duration from 'dayjs/plugin/duration';

dayjs.extend(advancedFormat);
dayjs.extend(advancedDateFormats);
dayjs.extend(isBetween);
dayjs.extend(duration);

export default dayjs;
