import styled from 'styled-components';

import { studentOptionColors } from '../lib/colors';

export const DashboardMainWrapper = styled.div`
  @media only screen and (min-width: 62.5em) {
    width: calc(100% - 29.4rem);

    & > main {
      width: 100%;
    }
  }
`;

interface IStudentOptionStyleProps {
  index: number;
  divFirstChild?: boolean;
}

export const OverviewItems = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 100%);
  column-gap: 2.1rem;
  row-gap: 1.6rem;
  /* justify-content: center; */

  @media only screen and (min-width: 43.75em) {
    grid-template-columns: repeat(auto-fill, 25rem);
    grid-template-rows: 8rem;
    grid-auto-rows: 8rem;
  }
`;

export const OverviewItem = styled.button<{ isClient: boolean }>`
  font: inherit;
  border: none;
  text-align: center;
  background-color: ${({ isClient }) =>
    isClient
      ? 'rgba(var(--color-primary), 0.1)'
      : 'rgb(var(--color-neutral8))'};
  padding: 2.4rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  @media only screen and (min-width: 43.75em) {
    padding: 8px 4.8rem;
    flex-direction: row;
    justify-content: center;
  }
`;

export const OverviewItemSvgContainer = styled.div<{ isClient: boolean }>`
  padding: 4px 6px;
  width: fit-content;
  background-color: ${({ isClient }) =>
    isClient
      ? 'rgba(var(--color-primary), 0.6)'
      : 'rgb(var(--color-neutral4))'};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 43.75em) {
    margin-right: 1.3rem;
  }
`;

export const OverviewItemTextContainer = styled.div`
  color: rgb(var(--color-black));

  & > p:first-of-type {
    margin-top: 8px;
    font-weight: 600;
    font-size: 2.1rem;
    line-height: 3.2rem;

    @media only screen and (min-width: 43.75em) {
      margin-top: 0;
    }
  }

  & > p:last-of-type {
    font-size: 1.1rem;
    line-height: 1.6rem;
    text-transform: capitalize;
    width: max-content;
    margin: 0 auto;
  }
`;

export const StudentOption = styled.div<IStudentOptionStyleProps>`
  display: flex;
  align-items: center;
  color: rgb(var(--color-neutral3));
  text-transform: capitalize;

  & > *:first-child {
    margin-right: 1.6rem;
    font-weight: 600;
    text-transform: uppercase;
    background-color: ${({ index }) => studentOptionColors[index]};
    color: rgb(var(--color-white));
    border-radius: 5px;
    padding: 4px 9px;
    width: 3.75rem;
    text-align: center;

    ${({ divFirstChild }) =>
      divFirstChild &&
      `
      border-radius: 50%;
      height: 2.4rem;
      width: 2.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 7px;
    `}
  }

  & > p:last-child {
    cursor: pointer;
  }

  &:not(:last-child) {
    margin-bottom: 1.6rem;
    padding-bottom: 1.6rem;
    border-bottom: 1px solid rgb(var(--color-neutral7));
  }
`;
