import { useMutation } from '@tanstack/react-query';

import { addOne, apiV1 } from '../utils';

import ReactQueryKeys from '../keys';

interface TopUpResponse {
  url: string;
}

interface TopUpPayload {
  id: string;
  amount: number;
}

const topUpWallet = addOne<TopUpPayload, TopUpResponse>(
  apiV1,
  '/payments/wallet-top-up'
);

const useTopUpWallet = () => {
  return useMutation(topUpWallet, {
    mutationKey: [ReactQueryKeys.TOPUP_WALLET],
    onSuccess: (data) => {
      if (data && data.url) window.location.href = data.url;
    },
  });
};

export default useTopUpWallet;
